<div class="row" *ngIf="sub">
  <div class="col-12">
    <h4 [innerHTML]="translationService.getTranslation('subscription_header')"></h4>
  </div>
  <div class="col-12" [innerHTML]="translationService.getTranslation('subscription_intro')">
  </div>
  <div class="col-12" *ngIf="sub.state === 3"
       [innerHTML]="translationService.getTranslation('subscription_canceled')">
  </div>
  <h5 class="mt-5" [innerHTML]="translationService.getTranslation('label_subscriptionDetails')"></h5>
  <div class="form-group col-12">
    <div class="row">
      <div class="col-6" [innerHTML]="translationService.getTranslation('label_backendid')">
      </div>
      <div *ngIf="sub.userBackendId >= 0" class="col-6">
        {{ sub.userBackendId }}
      </div>
      <div *ngIf="sub.userBackendId < 0" class="col-6">
        {{ translationService.getTranslation("subscription_user_not_assigned") }}
      </div>
    </div>
  </div>
  <div class="form-group col-12">
    <div class="row">
      <div class="col-6" [innerHTML]="translationService.getTranslation('order_id')">
      </div>
      <div class="col-6" *ngIf="sub.backendId <= 0" [innerHTML]="translationService.getTranslation('no_order_id')">
      </div>
      <div class="col-6" *ngIf="sub.backendId > 0">
        {{ sub.backendId }}
      </div>
    </div>
  </div>
  <div class="form-group col-12">
    <div class="row">
      <div class="col-6" [innerHTML]="translationService.getTranslation('period')">
      </div>
      <div class="col-6">
        {{ sub.validDate.validFrom | date: translationService.getTranslation('dateFormat') }} -
        {{ (sub.validDate.validUntil) ? (sub.validDate.validUntil | date: translationService.getTranslation('dateFormat')) : translationService.getTranslation('subscription_endless') }}
      </div>
    </div>
  </div>
  <div class="form-group col-12" *ngIf="sub.commitmentDate && sub.commitmentDate > today">
    <div class="row">
      <div class="col-6" [innerHTML]="translationService.getTranslation('commitmentDate')">
      </div>
      <div class="col-6">
        {{ sub.commitmentDate | date: translationService.getTranslation('dateFormat') }}
      </div>
    </div>
  </div>
  <ng-container *ngIf="showAdditionalSubscriptionInfos">
    <div class="form-group col-12" *ngIf="sub.curPayment">
      <div class="row">
        <div class="col-6" [innerHTML]="translationService.getTranslation('payment_bankinfo')">
        </div>
        <div class="col-6">
          <div>
            {{ paymentMethodDesc }}
          </div>
          <div>
            {{ sub.curPayment.paymentType.paymentFrequency }}
          </div>
          <ng-container *ngIf="paymentMethodId && paymentMethodId.paymentType === 'DIRECTDEBITIBAN'">
            <div>
              {{ translationService.getTranslation('payment_iban') }} {{ sub?.curPayment?.bank?.swiftIBAN | bankAccount }}
            </div>
            <div>
              {{ translationService.getTranslation('payment_bic') }} {{ sub?.curPayment?.bank?.swiftBIC }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="form-group col-12">
      <div class="row">
        <div class="col-6" [innerHTML]="translationService.getTranslation('order_product')">
        </div>
        <div class="col-6">
          {{ sub.productDescription }}
        </div>
      </div>
    </div>
    <ng-container *ngIf="sub.variantDescription">
      <div class="form-group col-12">
        <div class="row">
          <div class="col-6" [innerHTML]="translationService.getTranslation('order_edition')">
          </div>
          <div class="col-6">
            {{ sub.variantDescription }}
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="sub.curDelivery">
      <h5 class="mt-5" [innerHTML]="translationService.getTranslation('address_delivery')"></h5>
      <div class="form-group col-12">
        <app-address-detail [address]="sub.curDelivery.address"
                            [person]="sub.curDelivery.person"></app-address-detail>
      </div>
    </ng-container>
    <ng-container *ngIf="sub.curBilling">
      <h5 class="mt-5" [innerHTML]="translationService.getTranslation('address_billing')"></h5>
      <div class="form-group col-12">
        <app-address-detail [address]="sub.curBilling.address"
                            [person]="sub.curBilling.person"></app-address-detail>
      </div>
    </ng-container>
  </ng-container>
  <div class="form-group col-12" *ngIf="faqButtonLink">
    <div class="row">
      <div class="col-6">

      </div>
      <div class="col-6">
        <button mat-raised-button color="primary"
                href="{{faqButtonLink}}">{{ translationService.getTranslation('subscription_faqButton') }}
        </button>
      </div>
    </div>
  </div>

  <ng-container *ngIf="sub.subId > 0 && showTerminationButton">
    <div class="form-group col-12 mt-5">
      <div class="row">
        <div class="col-6">
        </div>
        <div class="col-6">
          <button mat-raised-button *ngIf="sub.state !== 3; else subscriptionTerminated"
                  (click)="terminateSubscription()" [innerHTML]="translationService.getTranslation('button_terminate')">
          </button>
          <ng-template #subscriptionTerminated>
            <div
              *ngIf="sub.subId > 0 && sub.webId > 0">{{ translationService.getTranslation('subscription_terminated_webabo') }}
            </div>
            <div
              *ngIf="sub.subId > 0 && sub.webId < 0">{{ translationService.getTranslation('subscription_terminated_paradise') }}
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="mt-3 float-end">
  <button *ngIf="showBackToOverviewButton"
          mat-raised-button (click)="backToOverview()"
          [innerHTML]="translationService.getTranslation('button_back_to_overview')"></button>
</div>
